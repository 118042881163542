import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "masthead" };
const _hoisted_2 = { class: "container" };
const _hoisted_3 = { class: "masthead-subheading text-uppercase" };
const _hoisted_4 = { class: "masthead-heading" };
const _hoisted_5 = {
    class: "btn btn-primary btn-xl text-uppercase",
    href: "#services"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("header", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, _toDisplayString($setup.t('welcome')), 1),
            _createElementVNode("div", _hoisted_4, _toDisplayString($setup.t('shortIntro')), 1),
            _createElementVNode("a", _hoisted_5, _toDisplayString($setup.t('tellMeMore')), 1)
        ])
    ]));
}
