<template>
  <section class="page-section" id="services">
    <div class="container">
      <div class="text-center">
        <h2 class="section-heading text-uppercase">{{ t('services') }}</h2>
        <h3 class="section-subheading text-muted">{{ t('servicesSubMsg') }}</h3>
      </div>
      <div class="row text-center">
        <div class="col-md-4">
                        <span class="fa-stack fa-4x">
                            <i class="fas fa-circle fa-stack-2x text-primary"></i>
                            <i class="fas fa-code fa-stack-1x fa-inverse"></i>
                        </span>
          <h4 class="my-3">{{t('development')}}</h4>
          <p class="text-muted">{{t('developmentDesc')}}</p>
        </div>
        <div class="col-md-4">
                        <span class="fa-stack fa-4x">
                            <i class="fas fa-circle fa-stack-2x text-primary"></i>
                            <i class="fas fa-cloud fa-stack-1x fa-inverse"></i>
                        </span>
          <h4 class="my-3">{{t('cloud')}}</h4>
          <p class="text-muted">{{t('cloudDesc')}}</p>
        </div>
        <div class="col-md-4">
                        <span class="fa-stack fa-4x">
                            <i class="fas fa-circle fa-stack-2x text-primary"></i>
                            <i class="fas fa-handshake fa-stack-1x fa-inverse"></i>
                        </span>
          <h4 class="my-3">{{t('consult')}}</h4>
          <p class="text-muted">{{t('consultDesc')}}</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {useI18n} from "vue-i18n";

export default {
  name: "CompanyServicesShowCase",
  setup() {
    const {t} = useI18n({
      inheritLocale: true,
      useScope: 'local'
    })
    // Something todo ..

    return {t}
  }
}
</script>

<i18n>
{
  "en": {
    "services": "Services",
    "servicesSubMsg": "Find out what services we offer for you!",
    "development": "Software development",
    "developmentDesc": "We develop the software of your liking! Depending on the problem, budget and use cases, we will find an optimal technological solution for you. From analysis to implementation and beyond, we are your partner.",
    "cloud": "Cloud",
    "cloudDesc": "As a partner of the largest cloud providers, we bring your software infrastructure to the cloud. This allows you to achieve the highest availability, reliability, scalability as well as the latest security standards!",
    "consult": "Consulting",
    "consultDesc": "We analyse your processes together with you and advise you on the digital transformation of these.",
  },
  "de": {
    "services": "Unsere Leistungen",
    "servicesSubMsg": "Finden Sie heraus, welche Dienstleistungen wir für Sie anbieten!",
    "development": "Softwareentwicklung",
    "developmentDesc": "Wir entwickeln die Software, die Sie sich wünschen! Je nach Problemstellung, Budget und Anwendungsfall finden wir für Sie eine optimale technologische Lösung. Von der Analyse bis zur Implementierung und darüber hinaus, sind wir Ihr Partner.",
    "cloud": "Cloud",
    "cloudDesc": "Als Partner der größten Cloud-Anbieter bringen wir Ihre Software-Infrastruktur in die Cloud. So erreichen Sie höchste Verfügbarkeit, Zuverlässigkeit, Skalierbarkeit sowie die neuesten Sicherheitsstandards!",
    "consult": "Beratung",
    "consultDesc": "Wir analysieren gemeinsam mit Ihnen Ihre Prozesse und beraten Sie bei der digitalen Transformation dieser.",

  }
}
</i18n>

<style scoped>

</style>
