<template>
  <nav id="mainNav" class="navbar navbar-expand-lg navbar-dark fixed-top">
    <div class="container-fluid">
      <a class="navbar-brand" href="/"><img alt="..." src="@/assets/img/Logo_Final.png"/></a>
      <button aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation"
              class="navbar-toggler" data-bs-target="#navbarResponsive" data-bs-toggle="collapse" type="button">
        Menu
        <i class="fas fa-bars ms-1"></i>
      </button>
      <div id="navbarResponsive" class="collapse navbar-collapse">
        <ul class="navbar-nav text-uppercase ms-auto py-4 py-lg-0">
          <li class="nav-item"><a class="nav-link" href="/#services">{{t('services')}}</a></li>
          <li class="nav-item"><a class="nav-link" href="#contact">{{t('contact')}}</a></li>
          <li class="nav-item"><router-link class="nav-link" to="/impressum">Impressum
          </router-link>
          </li>
          <li class="nav-item"><router-link class="nav-link" to="/data-protection">
            {{t('data-protection')}}
          </router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>

  <div id="main-container" class="container-fluid">
    <transition name="fade">
      <CookieConsent v-if="!consense" :ref="cookieConsent" @accepted="cookieOkay"></CookieConsent>
    </transition>
    <router-view/>
  </div>
  <!-- Masthead-->
  <!-- Services-->
  <!-- Portfolio Grid-->

  <!-- About-->

  <!-- Team-->

  <!-- Clients-->
  <!-- Contact-->

  <!-- Footer-->
  <footer class="footer py-4">
    <div class="container">
      <cookie-law theme="dark-lime"></cookie-law>
      <div class="row align-items-center">
        <div class="col-lg-4 text-lg-start">Copyright &copy; ProcEvolution UG</div>
        <div class="col-lg-4 my-3 my-lg-0">
          <a class="btn btn-dark btn-social mx-2" href="https://www.xing.com/pages/procevolutionug"><i class="fab fa-xing"></i></a>
          <a class="btn btn-dark btn-social mx-2" href="https://www.facebook.com/procevolution/"><i class="fab fa-facebook-f"></i></a>
          <a class="btn btn-dark btn-social mx-2" href="https://www.linkedin.com/company/28531543/admin/"><i class="fab fa-linkedin-in"></i></a>
        </div>
        <div class="col-lg-4 text-lg-end">
          <router-link class="link-dark text-decoration-none me-2" to="/impressum">Impressum</router-link>
          <router-link class="link-dark text-decoration-none" to="/data-protection">{{t('data-protection')}}</router-link>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import {useI18n } from "vue-i18n";
import {defineComponent, ref} from "vue";
import CookieConsent from "@/components/CookieConsent";

export default defineComponent({
  components: {
    CookieConsent
  },
  setup() {

    const consense = ref(false);
    const cookieConsent = ref();

    const { t } = useI18n({
      locale: 'en',
      inheritLocale: true,
      useScope: 'local'
    })

    const cookieOkay = () => {
      console.info(cookieConsent);
      consense.value = true;
    };

    const selection = ref();
    return {
      t,
      selection,
      cookieOkay,
      cookieConsent,
      consense
    }
  }
})
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity 1s ease;
}

.fade-enter-from, .fade-leave-to {
  opacity: 0;
}


.form-select{
  background: transparent;
  color: var(--bs-light);
  text-align: center;
  scroll-behavior: unset;
  overflow-y: hidden;
  border: var(--bs-primary-darken);
}

.form-select:active{
  background: var(--bs-primary-darken);
}

option:checked{
  color: var(--bs-light);
  background: var(--bs-primary-darken);
}
</style>

<i18n>
{
  "en": {
    "services": "Services",
    "data-protection": "Data Protection",
    "contact": "Contact"
  },
  "de": {
    "services": "Dienstleistungen",
    "data-protection": "Datenschutzerklärung",
    "contact": "Kontakt"

  }
}
</i18n>
