<template>
  <section class="page-section bg-light" id="team">
    <div class="container">
      <div class="text-center">
        <h3 class="section-heading text-uppercase">{{t('ourLead')}}</h3>
        <!--
        <h3 class="section-subheading text-muted">Lorem ipsum dolor sit amet consectetur.</h3>
        -->
      </div>
      <div class="row">
        <div class="col-12">
          <div class="team-member">
            <img class="mx-auto rounded-circle" src="@/assets/img/team/bos.jpg" alt="..." />
            <h4>Sebastian Bos</h4>
            <p class="text-muted">CEO</p>
            <a class="btn btn-primary btn-social mx-2" href="https://www.xing.com/profile/Sebastian_Bos2/cv"><i class="fab fa-xing"></i></a>
            <a class="btn btn-primary btn-social mx-2" href="https://www.linkedin.com/in/sebastian-bos-154621130/"><i class="fab fa-linkedin-in"></i></a>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-8 mx-auto text-center">
          <figure>
            <blockquote class="blockquote">
              <p>"{{t('ceoMsg')}}"</p>
            </blockquote>
          </figure>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {useI18n} from "vue-i18n";

export default {
  name: "Team",
  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'local'
    })
    // Something todo ..

    return { t }
  }
}
</script>

<style scoped>

</style>

<i18n>
{
  "en": {
    "ourLead": "Our management",
    "shortIntro": "We develop your software of tomorrow.",
    "tellMeMore": "Tell Me More",
    "ceoMsg": "We are not the typical software developers who just develop on the fly. Together with you, the customer, we tailor your optimal solution based on the most modern technologies."
  },
  "de": {
    "ourLead": "Unsere Geschäftsführung",
    "shortIntro": "Wir entwickeln Ihre Software von morgen.",
    "tellMeMore": "Mehr erfahren",
    "ceoMsg": "Wir sind nicht die typischen Softwareentwickler, die einfach drauflos entwickeln. Gemeinsam mit Ihnen, dem Kunden, konzipieren wir Ihre optimale Lösung auf der Basis modernster Technologien."


  }
}
</i18n>
