<template>
  <div>
    <!-- Masthead-->
    <Masthead></Masthead>
    <ClientsShowCase></ClientsShowCase>
    <!-- Services-->
    <CompanyServicesShowCase></CompanyServicesShowCase>
    <Team></Team>
    <Contact></Contact>
  </div>
</template>

<script>
import Masthead from "@/components/Masthead.vue";
import CompanyServicesShowCase from "@/components/CompanyServicesShowCase.vue";
import About from "@/views/About";
import Team from "@/components/Team";
import Contact from "@/components/Contact";
import ClientsShowCase from "@/components/ClientsShowCase"; // @ is an alias to /src

export default {
  name: 'Home',
  components: {
    ClientsShowCase,
    Contact,
    Team,
    CompanyServicesShowCase,
    Masthead,
  },
}
</script>
