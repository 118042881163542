export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "ourLead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our management"])},
        "shortIntro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We develop your software of tomorrow."])},
        "tellMeMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tell Me More"])},
        "ceoMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are not the typical software developers who just develop on the fly. Together with you, the customer, we tailor your optimal solution based on the most modern technologies."])}
      },
      "de": {
        "ourLead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unsere Geschäftsführung"])},
        "shortIntro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir entwickeln Ihre Software von morgen."])},
        "tellMeMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehr erfahren"])},
        "ceoMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir sind nicht die typischen Softwareentwickler, die einfach drauflos entwickeln. Gemeinsam mit Ihnen, dem Kunden, konzipieren wir Ihre optimale Lösung auf der Basis modernster Technologien."])}
      }
    }
  })
}
