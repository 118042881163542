<template>
  <section class="page-section" id="contact">
    <div class="container">
      <div class="text-center">
        <h2 class="section-heading text-uppercase">{{t('contact')}}</h2>
        <p class="section-heading text-uppercase">
          info@procevolution.com
        </p>
      </div>
    </div>
  </section>
</template>

<script>
import {useI18n} from "vue-i18n";

export default {
  name: "Contact",
  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'local'
    })
    // Something todo ..

    return { t }
  }
}
</script>
<i18n>
{
  "en": {
    "contact": "Contact",
    "shortIntro": "We develop your software of tomorrow.",
    "tellMeMore": "Tell Me More"
  },
  "de": {
    "contact": "Kontakt",
    "shortIntro": "Wir entwickeln Ihre Software von morgen.",
    "tellMeMore": "Mehr erfahren"

  }
}
</i18n>
<style scoped>

</style>
