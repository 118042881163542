<template>
  <div class="d-flex justify-content-center mt-5 h-100">
    <div class="d-flex align-items-center align-self-center card p-3 text-center cookies shadow-lg">
      <img alt="representing a cookie" src="@/assets/img/cookie.png" width="50">
      <span class="mt-2">{{t('msg')}}</span>
      <!--
      <a class="d-flex align-items-center" href="#">Learn more<i class="fa fa-angle-right ml-2"></i></a>
      -->
      <button class="btn btn-dark mt-3 px-4" type="button" @click="accept">Okay</button>
    </div>
  </div>
</template>

<script>
import {defineComponent} from "vue";
import {useI18n} from "vue-i18n";

export default defineComponent({
  name: "CookieConsent",
  props: {},
  emits: [
      'accepted',
      'decline'
  ],
  setup(props, context) {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'local'
    })

    const accept = () => {
      context.emit('accepted');
    };

    return {
      t, accept
    }
  }
})
</script>

<i18n>
{
  "en": {
    "msg": "We use third party cookies like fontawesome, to give you the best experience as possible."
  },
  "de": {
    "msg": "Wir verwenden Cookies von Drittanbietern wie fontawesome, um Ihnen das bestmögliche Erlebnis zu bieten."

  }
}
</i18n>
<style scoped>

.cookies{
  position: fixed;
  bottom: 40px;
  right: 5%;
  z-index: 1;
}

.card {
  width: 350px;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 2px solid #d2d2dc;
  border-radius: 25px;
  -webkit-box-shadow: 0px 0px 5px 0px rgb(249, 249, 250);
  -moz-box-shadow: 0px 0px 5px 0px rgba(212, 182, 212, 1);
  box-shadow: 0px 0px 5px 0px rgb(161, 163, 164)
}

.cookies a {
  text-decoration: none;
  color: #000;
  margin-top: 8px
}

.cookies a:hover {
  text-decoration: none;
  color: blue;
  margin-top: 8px
}
</style>
