export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome To ProcEvolution!"])},
        "shortIntro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We develop your software of tomorrow."])},
        "tellMeMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tell Me More"])}
      },
      "de": {
        "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Willkommen bei der ProcEvolution!"])},
        "shortIntro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir entwickeln Ihre Software von morgen."])},
        "tellMeMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehr erfahren"])}
      }
    }
  })
}
