export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])},
        "servicesSubMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find out what services we offer for you!"])},
        "development": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Software development"])},
        "developmentDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We develop the software of your liking! Depending on the problem, budget and use cases, we will find an optimal technological solution for you. From analysis to implementation and beyond, we are your partner."])},
        "cloud": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cloud"])},
        "cloudDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As a partner of the largest cloud providers, we bring your software infrastructure to the cloud. This allows you to achieve the highest availability, reliability, scalability as well as the latest security standards!"])},
        "consult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consulting"])},
        "consultDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We analyse your processes together with you and advise you on the digital transformation of these."])}
      },
      "de": {
        "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unsere Leistungen"])},
        "servicesSubMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finden Sie heraus, welche Dienstleistungen wir für Sie anbieten!"])},
        "development": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Softwareentwicklung"])},
        "developmentDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir entwickeln die Software, die Sie sich wünschen! Je nach Problemstellung, Budget und Anwendungsfall finden wir für Sie eine optimale technologische Lösung. Von der Analyse bis zur Implementierung und darüber hinaus, sind wir Ihr Partner."])},
        "cloud": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cloud"])},
        "cloudDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als Partner der größten Cloud-Anbieter bringen wir Ihre Software-Infrastruktur in die Cloud. So erreichen Sie höchste Verfügbarkeit, Zuverlässigkeit, Skalierbarkeit sowie die neuesten Sicherheitsstandards!"])},
        "consult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beratung"])},
        "consultDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir analysieren gemeinsam mit Ihnen Ihre Prozesse und beraten Sie bei der digitalen Transformation dieser."])}
      }
    }
  })
}
