export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])},
        "data-protection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Protection"])},
        "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])}
      },
      "de": {
        "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dienstleistungen"])},
        "data-protection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenschutzerklärung"])},
        "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt"])}
      }
    }
  })
}
