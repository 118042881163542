export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We use third party cookies like fontawesome, to give you the best experience as possible."])}
      },
      "de": {
        "msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir verwenden Cookies von Drittanbietern wie fontawesome, um Ihnen das bestmögliche Erlebnis zu bieten."])}
      }
    }
  })
}
