<template>
  <div class="py-5">
    <div class="container">
      <div class="row justify-content-center">
        <h3 style="text-align: center;" class="section-heading text-uppercase">{{t('partners')}}</h3>
        <div class="col-md-4 col-sm-4 my-3">
          <a href="https://www.streamo.com/"><img class="img-fluid img-brand d-block mx-auto" src="@/assets/img/logos/streamo_logo.png" alt="..." /></a>
        </div>
        <div class="col-md-4 col-sm-4 my-3">
          <a href="https://www.microsoft.com/de-de/"><img class="img-fluid img-brand d-block mx-auto" src="@/assets/img/logos/proc-website-microsoft.png" alt="..." /></a>
        </div>
        <div class="col-md-4 col-sm-4 my-3">
          <a href="https://www.datadoghq.com/"><img class="img-fluid img-brand d-block mx-auto" src="@/assets/img/logos/dd_logo_h_rgb.png" alt="..." /></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {useI18n} from "vue-i18n";

export default {
  name: "ClientsShowCase",
  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'local'
    })
    // Something todo ..

    return { t }
  }
}
</script>

<style scoped>

</style>

<i18n>
{
  "en": {
    "partners": "Our Partners",
  },
  "de": {
    "partners": "Unsere Partner",
  }
}
</i18n>
